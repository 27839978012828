import React from "react";
import "./index.css";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

function Preloader() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width:'100%',
        position:'fixed',
        backgroundColor:"white",
        zIndex:999
      }}
    >
      <div style={{ display: "flex" }}>
        <ClimbingBoxLoader
          color={"#4489ff"}
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
}

export default Preloader;
