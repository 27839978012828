import React,{ useEffect} from "react";
import { Route, Routes } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import { ToastContainer} from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from "./ScrollToTop";
import Preloader from "./Preloader";

const HomePage = React.lazy(()=>import('./components/pages/homePage/HomePage'))
const Delete = React.lazy(()=>import('./components/pages/privacy/Delete'))
const Privacy = React.lazy(()=>import('./components/pages/privacy/Privacy'))


function App() {
  // const [isLoading,setIsLoading]= useState(true)
  // useEffect(() => {
  //   const handleLoad = () => {
  //     setIsLoading(false);
  //   };
  //   const handleDOMContentLoaded = () => {
  //     setIsLoading(false);
  // };

  //   // Listen for the window load event
  //   window.addEventListener('load', handleLoad);
  //   document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  //   return () => {
  //     window.removeEventListener('load', handleLoad);
  //     document.removeEventListener('DOMContentLoaded', handleDOMContentLoaded);
  //   };
  // }, []);

  useEffect(() => {
    AOS.init({
      duration: 500, // Animation duration
      easing: "ease-in-sine",
      delay: 50, // Easing type
    });
  }, []);

  return (
    <React.Suspense fallback={<Preloader/>}>
    <>
    <ToastContainer newestOnTop={true} autoClose={5000} />
    <ScrollToTop/>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/delete" element={<Delete />} />
      <Route path="/privacy" element={<Privacy />} />
    </Routes>
    </>
  </React.Suspense>
  );
}

export default App;
